import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Amplify, { API, graphqlOperation, Auth, DataStore, Predicates }  from "aws-amplify";
import awsConfig from "../../aws-exports";
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react'
import * as Queries from '../../graphql/queries'
import { 
  createUser as CreateUser, 
  createPet as CreatePet,
  createPreference as CreatePreference, 
  updateUser as UpdateUser 
} from '../../graphql/mutations'

Amplify.configure(awsConfig); 

class Advanced extends React.Component {
  constructor(props) {
    super(props)
      
  }

  UNSAFE_componentWillMount = async () => {
    let data = await API.graphql(graphqlOperation(Queries.listPets))
    console.log('pets in data = ', data.data);
    
    data = await API.graphql(graphqlOperation(Queries.listUsers))
    console.log('users in data = ', data.data.listUsers);

    let filter = {
      permalink: {
          eq: "new-post" // filter priority = 1
      }
    };
    // data = await API.graphql(graphqlOperation(Queries.listPosts, {filter: filter}))
    // console.log('Posts in data = ', data.data.listPosts);
  }
  render() {
    return (
      <div className="wrapper">
      <header>
        <Link to="/">Go back to "Home"</Link>
      </header>
      <main>
        <h1>post.title</h1>
        <em>post.date</em> - post.topic
        
      </main>
    </div>
    )
  }
}

export default Advanced
